import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import Layout from "../components/Layout";
import Button from "../components/Button";
import Input from "../components/Input";
import Checkbox from "../components/Checkbox";
import PageTitle from "../components/PageTitle";
import Spinner from "../components/Spinner";
import InfoTooltip from "../components/InfoTooltip";
import MonthAutosuggest from "../components/MonthAutosuggest";

import { useFormContext } from "../context/formContext";
import { useLangContext } from "../context/langContext";
import { useCountryContext } from "../context/countryContext";

import { device, labelStyles } from "../globalStyles";

import I18N from "../constants/languages/NL/nl_translations";
import { preventEnterSubmit } from "../constants/utils";

import ErrorIcon from "../svg/error.svg";
import Seo from "../components/SEO";

const StyledLayout = styled(Layout)`
  position: relative;
`;

const Container = styled.div`
  padding: var(--gutter);
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  @media (${device.minTablet}) and (min-height: 890px) {
    padding-top: 80px;
  }

  form {
    width: 100%;
    @media (${device.minTablet}) {
      max-width: min(65vw, 872px);
    }
    input {
      margin-bottom: 3.2rem;
    }
    button {
      margin: auto;
    }
  }
`;

const NamesContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    width: 100%;
  }

  @media (${device.minTablet}) {
    flex-direction: row;
    & > div {
      margin-left: 8px;
      margin-right: 8px;
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const BirthDate = styled.div`
  display: flex;
  align-items: flex-end;

  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  & > input {
    width: calc(100% - 3.2rem); // offset padding
  }
`;

const InputLabel = styled.label`
  display: flex;
  font-family: "Noa", sans-serif;
  text-align: left;
  margin-bottom: 8px;
  align-items: center;

  & > div {
    margin-left: 4px;
    transform: translateY(-1px);
  }
`;

const StyledCheckbox = styled(Checkbox)`
  text-align: start;
  margin-bottom: 32px;
  && > input {
    margin: 0;
  }
  p {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Read = styled.a`
  text-decoration: none;
  color: var(--blue);
  font-weight: 700;

  span {
    display: inline-block;
    font-weight: 100;
  }
`;

const ReadMore = styled(Read)`
  white-space: nowrap;
  span {
    transform: translate(2px, -5px) scale(1.8, 1.2);
  }
`;

const ReadLess = styled(Read)`
  margin-bottom: 8px;
  display: block;
  span {
    transform: translate(2px, 4px) scale(1.3);
  }
`;

const Privacy = styled.p`
  color: var(--grey);
  text-align: start;
  margin-bottom: 32px;
  a {
    color: var(--grey);
  }
`;

const Error = styled.p`
  color: var(--red);
  text-align: start;
  margin-bottom: 32px;

  span {
    font-family: "Noa Bold", sans-serif;
    font-weight: 700;
  }
`;

const ErrorCheckbox = styled.p`
  ${labelStyles};
  color: var(--red);
  display: flex;
  align-items: center;

  margin-bottom: 32px;
  svg {
    margin-right: 8px;
  }
`;

const ErrorInput = styled.p`
  ${labelStyles};
  color: var(--red);
  margin-top: -2.8rem;
  margin-left: calc(var(--horizontal-padding) + 1px);
  margin-bottom: 3.2rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

const PersonalDetailsPage = () => {
  const { form, setForm } = useFormContext();
  const { country } = useCountryContext();
  const { lang } = useLangContext();
  const copy = I18N[lang]["personal-details"];

  const [readMore, setReadMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const [errorBdate, setErrorBdate] = useState("");

  const [firstName, setFirstName] = useState(form.first_name);
  const [lastName, setLastName] = useState(form.last_name);
  const [email, setEmail] = useState(form.email_address);
  const [day, setDay] = useState(form.day);
  const [month, setMonth] = useState(form.month);
  const [year, setYear] = useState(form.year);
  const [checked, setChecked] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) return;

    setError("");
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    const { checkbox, day, year, ...postProps } = formProps; // month is handled in a more complex input now
    const date_of_birth = month + "-" + day + "-" + year;

    setLoading(true);

    setForm({
      ...form,
      ...postProps,
      date_of_birth,
    });

    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      "https://cloud.klm-mail.com/klmnl-email-api?action=emailLead&",
      true
    );
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(
      JSON.stringify({
        ...form,
        ...postProps,
        date_of_birth,
        language: lang,
        country,
      })
    );

    xhr.onload = function (this) {
      let response;
      setLoading(false);
      if (this?.response) {
        try {
          response = JSON.parse(this.response);
        } catch (e) {
          console.error(e);
        }
      }
      if (response?.status === "Error") {
        setError(response?.message);
      } else if (this.status >= 200 && this.status < 300) {
        navigate("/ready");
      } else if (this.statusText) {
        setError(this.statusText);
      }
    };
    xhr.onerror = function (this, error) {
      setLoading(false);
      console.error("this error", this);
      console.error("event error", error);
    };
  };

  useEffect(() => {
    setErrorBdate("");
    if (!day || !month || !year) return;
    const bdate = `${year}/${month.padStart(2, "0")}/${day.padStart(2, "0")}`;
    const today = new Date();
    const bdate18yo =
      `${today.getFullYear() - 18}/` +
      `${today.getMonth() + 1}`.padStart(2, "0") +
      "/" +
      `${today.getDate()}`.padStart(2, "0");
    if (bdate18yo < bdate) {
      setErrorBdate(copy.errorDob);
    }
  }, [day, month, year]);

  return (
    <StyledLayout title={copy.pageTitle} pageName="Personal details">
      <Container>
        <PageTitle label="3/3" title={copy.title} />

        <form onSubmit={handleSubmit}>
          <NamesContainer>
            <div>
              <Input
                onChange={(e) => setFirstName(e.target.value)}
                aria-label={copy.firstName}
                type="text"
                name="first_name"
                id="first_name"
                defaultValue={firstName}
                autoFocus
                required
              />
            </div>

            <div>
              <Input
                onChange={(e) => setLastName(e.target.value)}
                aria-label={copy.lastName}
                type="text"
                name="last_name"
                id="last_name"
                defaultValue={lastName}
                required
              />
            </div>
          </NamesContainer>

          <Input
            onChange={(e) => setEmail(e.target.value)}
            aria-label={copy.email}
            type="email"
            name="email_address"
            id="email_address"
            defaultValue={email}
            required
          />

          <InputLabel>
            {copy.dob} <InfoTooltip>{copy.tootltipDob}</InfoTooltip>
          </InputLabel>
          <BirthDate>
            <Input
              onChange={(e) => setDay(e.target.value)}
              placeholder={copy.DD}
              type="number"
              min={1}
              max={31}
              name="day"
              id="day"
              defaultValue={day}
              required
            />
            <MonthAutosuggest
              id="month"
              label=""
              onChange={setMonth}
              required
              inputProps={{
                onKeyDown: preventEnterSubmit,
                placeholder: copy.MM,
              }}
              defaultValue={month}
            />
            <Input
              onChange={(e) => setYear(e.target.value)}
              placeholder={copy.YYYY}
              min={1900}
              max={2010}
              type="number"
              name="year"
              id="year"
              defaultValue={year}
              required
            />
          </BirthDate>
          {errorBdate && (
            <ErrorInput>
              <ErrorIcon />
              {errorBdate}
            </ErrorInput>
          )}

          <StyledCheckbox
            aria-label="accept T&C"
            name="checkbox"
            id="checkbox"
            checked={checked}
            aria-checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          >
            <p>
              {copy.checkboxPreview}{" "}
              {!readMore && (
                <ReadMore
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setReadMore(true);
                  }}
                >
                  {copy.readMore} <span>⌄</span>
                </ReadMore>
              )}
            </p>
            {readMore && (
              <>
                {copy.checkboxFull}
                <ReadLess
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setReadMore(false);
                  }}
                >
                  {copy.readLess} <span>⌃</span>
                </ReadLess>
              </>
            )}
            {!checked && (
              <ErrorCheckbox>
                <ErrorIcon />
                {copy.checkboxUnchecked}
              </ErrorCheckbox>
            )}
          </StyledCheckbox>

          <Privacy
            // Might be better with a onBlur on every component
            onClick={() => {
              setForm({
                ...form,
                first_name: firstName,
                last_name: lastName,
                email_address: email,
                day,
                month,
                year,
              });
            }}
          >
            {copy.legal}
          </Privacy>

          {error && <Error>{copy.error(error)}</Error>}
          <Button
            type="submit"
            disabled={
              !firstName ||
              !lastName ||
              !email ||
              !day ||
              !month ||
              !year ||
              errorBdate ||
              !checked
            }
          >
            {loading ? <Spinner /> : copy.button}
          </Button>
        </form>
      </Container>
    </StyledLayout>
  );
};

export default PersonalDetailsPage;

export const Head = () => <Seo />;
